import React from 'react';
import { useRoutes } from 'react-router-dom';
import Login from '../pages/login/Login';
import { DefaultLayout } from '@shared/layouts';
import PrivateRoute from '@shared/layouts/PrivateRoute';
import InfoUser from '@pages/info-use';
import ResetPassword from '@pages/reset-password';
import Homepage from '@pages/home';
import Eventpage from '@pages/event';
import Levelpage from '@pages/level';
import HelperLayout from '@shared/layouts/HeplerLayout';
import Detailpage from '@pages/detail';
import OtherLayout from '@shared/layouts/OtherLayout';
import Otherpage from '@pages/other';
import Memberpage from '@pages/member';
import MemberLayout from '@shared/layouts/MemberLayout';
import WithDraw from '@pages/member/withdraw';
import InfoBankComponent from '@components/infoBank';
import HistoryDeposit from '@pages/member/history-deposit';
import Profile from '@pages/member/profile';
import InvitePage from '@pages/member/invite';
import ChangePass from '@pages/member/change-pass';
import HistoryWithdraw from '@pages/member/history-withdraw';
import Point from '@pages/member/point';
import HistoryPoint from '@pages/member/history-point';
import HistoryInvite from '@pages/member/history-invite';
import Register from '@pages/register';
import ForgotPass from '@pages/forgot-password';
import PageNew from '@pages/news';
import NewDetail from '@pages/new-detail';
import GameDetail from '@pages/game-detail';
import PostPage from '@pages/post';
import GameUser from '@pages/GameUser';
import NotFound from '@pages/notfound';
import ListPostPage from '@pages/list-post';
import ContactPage from '@pages/contact';

const AuthRoutes: React.FC = () => {
  const LoginPrivate = <PrivateRoute element={Login} />;
  const elements = useRoutes([
    // {
    //   path: '/',
    //   element: LoginPrivate,
    // },
    // {
    //   path: '/login',
    //   element: LoginPrivate,
    // },

    {
      element: <DefaultLayout />,
      children: [
        {
          path: '/',
          element: <Homepage />,
        },

        {
          path: 'thong-tin',
          element: <InfoUser />,
        },
        {
          path: 'cap-bac',
          element: <Levelpage />,
        },
        {
          path: 'lien-he',
          element: <ContactPage />,
        },
        {
          path: 'dang-ky',
          element: <Register />,
        },
        {
          path: 'tro-choi/:id',
          element: <GameDetail />,
        },
        {
          path: 'quen-mat-khau',
          element: <ForgotPass />,
        },
        {
          path: 'tin-tuc',
          element: <PageNew />,
        },
        {
          path: 'tin-tuc/:id',
          element: <ListPostPage />,
        },
        {
          path: 'bai-viet/:id',
          element: <PostPage />,
        },
        {
          path: 'notfound',
          element: <NotFound />,
        },
        {
          path: '/:id',
          element: <Detailpage />,
        },
      ],
    },
    // {
    //   element: <HelperLayout />,
    //   children: [
    //     {
    //       path: '/:id',
    //       element: <Detailpage />,
    //     },
    //   ],
    // },

    {
      element: <OtherLayout title="Khác" />,
      children: [
        {
          path: '/khac',
          element: <Otherpage />,
        },
      ],
    },
    {
      element: (
        <PrivateRoute
          element={MemberLayout}
          meta={{
            requiresAuth: true,
          }}
        />
      ),
      path: 'thanh-vien',
      children: [
        {
          path: 'nap-tien',
          element: <Memberpage />,
        },
        {
          path: 'rut-tien',
          element: <WithDraw />,
        },
        {
          path: 'thong-tin-ngan-hang',
          element: <InfoBankComponent />,
        },
        {
          path: 'lich-su-nap-tien',
          element: <HistoryDeposit />,
        },
        {
          path: 'ho-so',
          element: <Profile />,
        },
        {
          path: 'gioi-thieu',
          element: <InvitePage />,
        },
        {
          path: 'doi-mat-khau',
          element: <ChangePass />,
        },
        {
          path: 'lich-su-rut-tien',
          element: <HistoryWithdraw />,
        },
        {
          path: 'chuyen-diem',
          element: <Point />,
        },
        {
          path: 'lich-su-chuyen-diem',
          element: <HistoryPoint />,
        },
        {
          path: 'lich-su-gioi-thieu',
          element: <HistoryInvite />,
        },
        {
          path: 'tro-choi',
          element: <GameUser />,
        },
      ],
    },
  ]);

  return elements;
};

export default AuthRoutes;
