import * as React from 'react';
import './style.scss';
import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import UploadMutipleImage from '@components/common/upload/UploadMutipleImage';
import { ICreateUser, IForgotFassword } from '@interfaces/User';
import { useAppDispatch, useAppSelector } from '@hook/index';
import { creatUser, forgotFassword, getOTP } from '@store/authSlice';
import showMessage from '@utils/helper';
import Countdown from 'react-countdown';
import { useNavigate } from 'react-router-dom';
import { getSetting } from '@store/settingSlice';

export default function ForgotPassComponent() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data: DataSetting } = useAppSelector((state) => state.settingSlice);
  const [start, setStart] = React.useState<boolean>(false);
  const [form] = Form.useForm();
  const handleChangeImg = (imgList: Object[]) => {};
  const [param, setParam] = React.useState<{ usernam: string }>();
  const [paramRestPass, setParamRestPass] = React.useState<IForgotFassword>();
  React.useEffect(() => {
    showMessage('Alo');
    dispatch(getSetting());
  }, []);
  React.useEffect(() => {
    if (localStorage.getItem('countdown')) {
      setStart(true);
    }
  }, [localStorage.getItem('countdown')]);
  const handleResetFile = () => {
    form.resetFields();
  };
  const handleSubmit = (event) => {
    setParam({ ...param, ...event });
    dispatch(
      getOTP({
        param: { ...param, ...event },
        callback: (message) => {
          setStart(true);
          localStorage.setItem('countdown', String(Date.now()));
          showMessage(message);
          handleResetFile();
        },
      }),
    );
  };

  const handleResetPass = (event: IForgotFassword) => {
    setParamRestPass({ ...param, ...event });
    dispatch(
      forgotFassword({
        param: { ...param, ...event },
        callback: (message) => {
          showMessage(message);
          handleResetFile();
          navigate('/');
        },
      }),
    );
  };
  const Completionist = () => <span>You are good to go!</span>;
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      setStart(false);
      localStorage.removeItem('countdown');
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <span>
          0{minutes}:{seconds < 10 ? '0' + seconds : seconds}
        </span>
      );
    }
  };

  return (
    <div className="layout-full">
      <div className="layout-max register-container">
        {!start && !localStorage.getItem('countdown') && (
          <>
            <div className="title">
              <i className="fa-solid fa-paper-plane"></i> Gửi mã xác nhận{' '}
            </div>{' '}
            <div className="sub-title red-color">
              Lưu ý: Bạn cần tương tác với BOT ở telegram:{' '}
              <a
                className="txt-blue2"
                style={{ color: 'blue' }}
                href={DataSetting?.listSetting?.bot_telegram}
                target="_blank"
                rel="noreferrer"
              >
                Link
              </a>{' '}
              trước để nhận mã OTP trước khi thực hiện.
            </div>
            <Form
              name="basic"
              className="form-register"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              onFinish={handleSubmit}
              layout={'vertical'}
              autoComplete="off"
              form={form}
            >
              <Row gutter={10} className="row-form" justify="center">
                <Col span={18}>
                  <Form.Item name="username" rules={[{ required: true, message: 'Vui lòng điền thông tin vào đây' }]}>
                    <Input placeholder="Nhập tên đăng nhập" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <div className="btnGroup">
                    <Button htmlType="submit" className="btn-check">
                      Gửi
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </>
        )}
        {(start || localStorage.getItem('countdown')) && (
          <>
            <div className="title">
              <i className="fa-solid fa-lock"></i> Thay đổi mật khẩu mới
            </div>{' '}
            <Form
              name="basic"
              className="form-register"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              onFinish={handleResetPass}
              layout={'vertical'}
              autoComplete="off"
              // form={form}
            >
              <Row gutter={10} className="row-form" justify="center">
                <Col span={18}>
                  <Form.Item name="username" rules={[{ required: true, message: 'Vui lòng điền thông tin vào đây' }]}>
                    <Input placeholder="Nhập tên đăng nhập" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10} className="row-form" justify="center">
                <Col span={18}>
                  <Form.Item name="otp_code" rules={[{ required: true, message: 'Vui lòng điền thông tin vào đây' }]}>
                    <Input placeholder="Nhập mã OTP" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10} className="row-form" justify="center">
                <Col span={18}>
                  <Form.Item name="password" rules={[{ required: true, message: 'Vui lòng điền thông tin vào đây' }]}>
                    <Input placeholder="Nhập mật khẩu mới" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10} className="row-form" justify="center">
                <Col span={18}>
                  <Form.Item
                    name="password_confirm"
                    rules={[{ required: true, message: 'Vui lòng điền thông tin vào đây' }]}
                  >
                    <Input placeholder="Xác nhận mật khẩu mới" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10} className="row-form" justify="center">
                <Col span={18}>
                  <div className="count-down">
                    {` Thời gian còn lại`}&nbsp;{' '}
                    <Countdown
                      zeroPadTime={2}
                      date={
                        localStorage.getItem('countdown') &&
                        Number(localStorage.getItem('countdown')) + 300000 > Date.now()
                          ? Number(localStorage.getItem('countdown')) + 300000
                          : Date.now() + 300000
                      }
                      renderer={renderer}
                    />
                  </div>
                </Col>
              </Row>

              <Row gutter={10} className="row-form" justify="center">
                <Col span={18}>
                  <div className="btnGroup">
                    <Button htmlType="submit" className="btn-check">
                      Gửi
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </>
        )}
      </div>
    </div>
  );
}
