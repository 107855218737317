import { IDataLevel } from '@interfaces/Level';
import { moneyInput } from '@utils/convert';
import * as React from 'react';

export interface ITableMobileProps {
  data: IDataLevel;
}

export default function TableMobile(props: ITableMobileProps) {
  const { data } = props;
  return (
    <>
      <div className="layout-full info">
        <div className="layout-max info-container">
          <div className="vipInfo">
            <div className="vipTitle">Đặc quyền VIP</div>GOAL123 vừa ra mắt chương trình thưởng Đặc quyền VIP với những
            ưu đãi chưa từng có: dịch vụ đặc biệt, quà tặng hoành tráng, và trải nghiệm khách hàng đỉnh cao. Hạng càng
            cao đặc quyền càng hấp dẫn. Đăng ký ngay hôm nay, quà tặng hấp dẫn đang chờ bạn!
            <div className="vipTitle">Giới thiệu</div>Hành trình vươn lên đỉnh cao VIP gồm các hạng ĐỒNG, BẠC, VÀNG,
            BẠCH KIM, KIM CƯƠNG, và GOAL123 CLUB. Thứ hạng càng cao, sản phẩm và dịch vụ càng đặc biệt.
            <div className="vipTitle">Trải nghiệm quyền lợi dành riêng cho từng hạng thành viên.</div>
          </div>
          <div className="vipPage">
            <div className="text">CẬP NHẬT GẦN NHẤT : 10/25/2022</div>
            <table className="eventList">
              <tbody>
                <tr className="title">
                  <td colSpan={12} className="icon icon-nova88">
                    HỖ TRỢ KHÁCH HÀNG
                  </td>
                </tr>
                <tr>
                  <td colSpan={12}>Hạng mức rút tiền trong ngày</td>
                </tr>
                <tr>
                  <td>{moneyInput(data.vip[0]?.withdrawal_today)}</td>
                  <td>{moneyInput(data.vip[1]?.withdrawal_today)}</td>
                  <td>{moneyInput(data.vip[2]?.withdrawal_today)}</td>
                  <td>{moneyInput(data.vip[3]?.withdrawal_today)}</td>
                  <td>{moneyInput(data.vip[4]?.withdrawal_today)}</td>
                  <td>{moneyInput(data.vip[5]?.withdrawal_today)}</td>
                  <td>{moneyInput(data.vip[6]?.withdrawal_today)}</td>
                  <td>{moneyInput(data.vip[7]?.withdrawal_today)}</td>
                  <td>{moneyInput(data.vip[8]?.withdrawal_today)}</td>
                  <td>{moneyInput(data.vip[9]?.withdrawal_today)}</td>
                  <td>{moneyInput(data.vip[10]?.withdrawal_today)}</td>
                </tr>
                <tr>
                  <td colSpan={12}>Số tiền nộp trong ngày</td>
                </tr>
                <tr>
                  <td>{moneyInput(data.vip[0]?.deposit_money)}</td>
                  <td>{moneyInput(data.vip[1]?.deposit_money)}</td>
                  <td>{moneyInput(data.vip[2]?.deposit_money)}</td>
                  <td>{moneyInput(data.vip[3]?.deposit_money)}</td>
                  <td>{moneyInput(data.vip[4]?.deposit_money)}</td>
                  <td>{moneyInput(data.vip[5]?.deposit_money)}</td>
                  <td>{moneyInput(data.vip[6]?.deposit_money)}</td>
                  <td>{moneyInput(data.vip[7]?.deposit_money)}</td>
                  <td>{moneyInput(data.vip[8]?.deposit_money)}</td>
                  <td>{moneyInput(data.vip[9]?.deposit_money)}</td>
                  <td>{moneyInput(data.vip[10]?.deposit_money)}</td>
                </tr>
                <tr>
                  <td colSpan={12}>Phần thưởng theo hạng</td>
                </tr>
                <tr>
                  <td>{data.vip[0]?.level_bonus}</td>
                  <td>{data.vip[1]?.level_bonus}</td>
                  <td>{data.vip[2]?.level_bonus}</td>
                  <td>{data.vip[3]?.level_bonus}</td>
                  <td>{data.vip[4]?.level_bonus}</td>
                  <td>{data.vip[5]?.level_bonus}</td>
                  <td>{data.vip[6]?.level_bonus}</td>
                  <td>{data.vip[7]?.level_bonus}</td>
                  <td>{data.vip[8]?.level_bonus}</td>
                  <td>{data.vip[9]?.level_bonus}</td>
                  <td>{data.vip[10]?.level_bonus}</td>
                </tr>
                <tr>
                  <td colSpan={12}>Phần thưởng theo ngày</td>
                </tr>
                <tr>
                  <td>{data.vip[0]?.day_bonus}</td>
                  <td>{data.vip[1]?.day_bonus}</td>
                  <td>{data.vip[2]?.day_bonus}</td>
                  <td>{data.vip[3]?.day_bonus}</td>
                  <td>{data.vip[4]?.day_bonus}</td>
                  <td>{data.vip[5]?.day_bonus}</td>
                  <td>{data.vip[6]?.day_bonus}</td>
                  <td>{data.vip[7]?.day_bonus}</td>
                  <td>{data.vip[8]?.day_bonus}</td>
                  <td>{data.vip[9]?.day_bonus}</td>
                  <td>{data.vip[10]?.day_bonus}</td>
                </tr>
                <tr>
                  <td colSpan={12}>Phần thưởng theo tuần</td>
                </tr>
                <tr>
                  <td>{data.vip[0]?.week_bonus}</td>
                  <td>{data.vip[1]?.week_bonus}</td>
                  <td>{data.vip[2]?.week_bonus}</td>
                  <td>{data.vip[3]?.week_bonus}</td>
                  <td>{data.vip[4]?.week_bonus}</td>
                  <td>{data.vip[5]?.week_bonus}</td>
                  <td>{data.vip[6]?.week_bonus}</td>
                  <td>{data.vip[7]?.week_bonus}</td>
                  <td>{data.vip[8]?.week_bonus}</td>
                  <td>{data.vip[9]?.week_bonus}</td>
                  <td>{data.vip[10]?.week_bonus}</td>
                </tr>
                <tr>
                  <td colSpan={12}>Phần thưởng theo tháng</td>
                </tr>
                <tr>
                  <td>{data.vip[0]?.month_bonus}</td>
                  <td>{data.vip[1]?.month_bonus}</td>
                  <td>{data.vip[2]?.month_bonus}</td>
                  <td>{data.vip[3]?.month_bonus}</td>
                  <td>{data.vip[4]?.month_bonus}</td>
                  <td>{data.vip[5]?.month_bonus}</td>
                  <td>{data.vip[6]?.month_bonus}</td>
                  <td>{data.vip[7]?.month_bonus}</td>
                  <td>{data.vip[8]?.month_bonus}</td>
                  <td>{data.vip[9]?.month_bonus}</td>
                  <td>{data.vip[10]?.month_bonus}</td>
                </tr>
                <tr>
                  <td colSpan={12}>Phần thưởng theo năm</td>
                </tr>
                <tr>
                  <td>{data.vip[0]?.year_bonus}</td>
                  <td>{data.vip[1]?.year_bonus}</td>
                  <td>{data.vip[2]?.year_bonus}</td>
                  <td>{data.vip[3]?.year_bonus}</td>
                  <td>{data.vip[4]?.year_bonus}</td>
                  <td>{data.vip[5]?.year_bonus}</td>
                  <td>{data.vip[6]?.year_bonus}</td>
                  <td>{data.vip[7]?.year_bonus}</td>
                  <td>{data.vip[8]?.year_bonus}</td>
                  <td>{data.vip[9]?.year_bonus}</td>
                  <td>{data.vip[10]?.year_bonus}</td>
                </tr>
              </tbody>
            </table>
            <div className="vipBanner">
              <img src="https://banner.goal123.org/h5/img/vip/vip_program_vn.jpg" alt="VIP Programme" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
