import PopoverArow from '@components/common/popover-arow';
import * as React from 'react';
import { useAppDispatch, useAppSelector } from '@hook/index';
import Logout from '@feature/auth/logout';
import { Link } from 'react-router-dom';
import { getAllLevel } from '@store/levelSlice';
export interface IInfoUserProps {}

export default function InfoUser(props: IInfoUserProps) {
  const dispatch = useAppDispatch();
  const { data: DataLevel } = useAppSelector((state) => state.levelSlice);
  const { data } = useAppSelector((state) => state.authSlice);
  React.useEffect(() => {
    dispatch(getAllLevel());
  }, []);
  const contentWallet = () => (
    <div className="transaction-list">
      <Link aria-current="page" to="/thanh-vien/nap-tien" className="menuSelect active">
        <span className="">Nạp điểm</span>
      </Link>
      <Link aria-current="page" to="/thanh-vien/rut-tien" className="menuSelect active">
        <span className="">Rút điểm</span>
      </Link>
      <Link aria-current="page" to="/thanh-vien/chuyen-diem" className="menuSelect active">
        <span className="">Chuyển điểm</span>
      </Link>
      <Link aria-current="page" to="/thanh-vien/ho-so" className="menuSelect active">
        <span className="">Hồ sơ</span>
      </Link>
      <Link className="menuSelect" to="/thanh-vien/thong-tin-ngan-hang">
        <span className="">Thông tin ngân hàng</span>
      </Link>
      <Link className="menuSelect" to="/thanh-vien/gioi-thieu">
        <span className="icon  ">Giới thiệu bạn bè</span>
      </Link>
      <Link className="menuSelect" to="/thanh-vien/doi-mat-khau">
        <span className="">Đổi Mật Khẩu</span>
      </Link>
      <Logout styleEl={{ width: 'auto' }}>
        <a>
          <i className="logout-icon fa-solid fa-right-from-bracket"></i> Thoát
        </a>
      </Logout>
    </div>
  );
  return (
    <div className="info-user">
      <PopoverArow content={contentWallet} allowArow={false} classCustom="dark-style space-top">
        <div className="info-user-content menu-item">
          <div className="wraper-img">
            <img src={DataLevel.vip.find((item) => item.level === data.level)?.image} alt="Vip" />
          </div>

          <span>{data.username}</span>
        </div>
      </PopoverArow>
    </div>
  );
}
