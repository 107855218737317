import * as React from 'react';
import './style.scss';
import { useAppDispatch, useAppSelector } from '@hook/index';
import { getPage } from '@store/detailSlice';
import { useLocation } from 'react-router-dom';
import LoadingModal from '@components/common/loading/LoadingModal';
import { Helmet } from 'react-helmet';
import SEO from '@components/common/seo';
export interface IDetailComponentProps {}

export default function HomeComponent(props: IDetailComponentProps) {
  const dispatch = useAppDispatch();
  const { loading, data } = useAppSelector((state) => state.detailSlice);
  let location = useLocation();
  React.useEffect(() => {
    if (!location) return;
    dispatch(getPage({ slug: 'trang-chu' }));
  }, [location]);
  if (loading)
    return (
      <div style={{ minHeight: 'calc(100vh)' }}>
        <LoadingModal loadingDefault={loading} />
      </div>
    );
  return (
    <>
      <SEO data={data.pageItem} />
      <div className="layout-full">
        <div className="layout-max">
          <div className="conent-page">
            <div dangerouslySetInnerHTML={{ __html: data.pageItem?.content || '' }} />
          </div>
        </div>
      </div>
    </>
  );
}
