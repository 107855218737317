import { RightOutlined } from '@ant-design/icons';
import { ITransfer } from '@interfaces/Transfer';
import { convert } from '@utils/convert';
import { Space } from 'antd';
import { useState } from 'react';
import DetailModal from './DetailModal';
import './style.scss';

const HistoryDeposit = ({ data = [] }: { data?: ITransfer[] }) => {
  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState<ITransfer>();
  return (
    <>
      <div className="table-mobile">
        {data.map((item, index) => (
          <div
            key={index}
            className="table-mobile-item"
            onClick={() => {
              setSelectedData(item);
              setOpen(true);
            }}
          >
            <div className="flex-column">
              <b>{item.game.title}</b>
              <span style={{ color: '#555' }}>{convert.moneyInput(item.money)} VND</span>
            </div>
            <div
              style={{
                gap: 10,
                marginLeft: 'auto',
                alignItems: 'center',
                color: '#999',
                display: 'flex',
              }}
            >
              <Space size="middle">
                {item.status === 0 ? (
                  <div
                    style={{
                      fontWeight: 600,
                      backgroundColor: '#48b0f7',
                      color: 'white',
                      padding: '2px 5px',
                      borderRadius: '5px',
                    }}
                  >
                    Đang xử lí
                  </div>
                ) : item.status === 1 ? (
                  <div
                    style={{
                      fontWeight: 600,
                      backgroundColor: '#52c41a',
                      color: 'white',
                      padding: '2px 5px',
                      borderRadius: '5px',
                    }}
                  >
                    Thành Công
                  </div>
                ) : (
                  <div
                    style={{
                      fontWeight: 600,
                      backgroundColor: 'red',
                      color: 'white',
                      padding: '2px 5px',
                      borderRadius: '5px',
                    }}
                  >
                    Thất bại
                  </div>
                )}
              </Space>{' '}
              <RightOutlined />
            </div>
          </div>
        ))}
      </div>
      <DetailModal open={open} onClose={() => setOpen(false)}>
        <div style={{ paddingBottom: 20 }}>
          <div style={{ borderBottom: '1px dashed #999', padding: '10px 0' }}>
            <div>
              <b>Tên người dùng</b>
            </div>
            <p>{selectedData?.user.username}</p>{' '}
          </div>
          <div style={{ borderBottom: '1px dashed #999', padding: '10px 0' }}>
            <b>Hoá đơn</b>
            <p>{selectedData?.bill_no}</p>
          </div>
          <div style={{ borderBottom: '1px dashed #999', padding: '10px 0' }}>
            <div>
              <b>Số tiền</b>
            </div>
            <p>{convert.moneyInput(selectedData?.money || 0)}</p>
          </div>
          <div style={{ borderBottom: '1px dashed #999', padding: '10px 0' }}>
            <div>
              <b>Điểm</b>
            </div>
            <p>{selectedData?.point}</p>
          </div>
          <div style={{ borderBottom: '1px dashed #999', padding: '10px 0' }}>
            <div>
              <b>Trò chơi</b>
            </div>
            <p>{selectedData?.game.title}</p>
          </div>
          <div style={{ borderBottom: '1px dashed #999', padding: '10px 0' }}>
            <div>
              <b>Trạng thái</b>
            </div>
            <Space size="middle" style={{ marginTop: 5 }}>
              {selectedData?.status === 0 ? (
                <div style={{ backgroundColor: '#48b0f7', color: 'white', padding: '2px 5px', borderRadius: '5px' }}>
                  Đang xử lí
                </div>
              ) : selectedData?.status === 1 ? (
                <div style={{ backgroundColor: '#52c41a', color: 'white', padding: '2px 5px', borderRadius: '5px' }}>
                  Thành Công
                </div>
              ) : (
                <div style={{ backgroundColor: 'red', color: 'white', padding: '2px 5px', borderRadius: '5px' }}>
                  Thất bại
                </div>
              )}
            </Space>
          </div>
          <div style={{ borderBottom: '1px dashed #999', padding: '10px 0' }}>
            <div>
              <b>Tạo lúc</b>
            </div>
            <p>{convert.convertDateFormat(selectedData?.date_create)}</p>
          </div>
        </div>
      </DetailModal>
    </>
  );
};

export default HistoryDeposit;
