import * as React from 'react';
import './style.scss';
import { Link } from 'react-router-dom';
export interface ISiderComponentProps {}

export default function SiderComponent(props: ISiderComponentProps) {
  return (
    <div id="leftMenu">
      <div className="menuModule">
        <button className="menuHeader">Giao dịch</button>
        <Link className="menuSelect" to="/thanh-vien/tro-choi">
          <span className="">Tài khoản cá cược</span>
        </Link>
        <Link className="menuSelect" to="/thanh-vien/nap-tien">
          <span className="">Nạp điểm</span>
        </Link>
        <Link className="menuSelect" to="/thanh-vien/chuyen-diem">
          <span className="chuyen-diem">Chuyển điểm</span>
        </Link>
        <Link className="menuSelect" to="/thanh-vien/rut-tien">
          <span className="">Rút điểm</span>
        </Link>
      </div>
      <div className="menuModule">
        <button className="menuHeader">Lịch sử</button>
        <Link className="menuSelect" to="/thanh-vien/lich-su-nap-tien">
          <span className="">Lịch sử nạp điểm</span>
        </Link>
        <Link className="menuSelect" to="/thanh-vien/lich-su-rut-tien">
          <span className="">Lịch sử rút tiền</span>
        </Link>
        <Link className="menuSelect" to="/thanh-vien/lich-su-gioi-thieu">
          <span className="icon  ">Lịch sử giới thiệu</span>
        </Link>

        <Link className="menuSelect" to="/thanh-vien/lich-su-chuyen-diem">
          <span className="">Lịch sử chuyển điểm</span>
        </Link>
      </div>
      <div className="menuModule">
        <button className="menuHeader">Tài khoản của tôi</button>
        <Link className="menuSelect" to="/thanh-vien/doi-mat-khau">
          <span className="">Đổi Mật Khẩu</span>
        </Link>
        <Link aria-current="page" to="/thanh-vien/ho-so" className="menuSelect active">
          <span className="">Hồ sơ</span>
        </Link>
        <Link className="menuSelect" to="/thanh-vien/thong-tin-ngan-hang">
          <span className="">Thông tin ngân hàng</span>
        </Link>
        <Link className="menuSelect" to="/thanh-vien/gioi-thieu">
          <span className="icon  ">Giới thiệu bạn bè</span>
        </Link>
      </div>
      {/* <div className="menuModule">
        <button className="menuHeader">Thông tin</button>
        <Link className="menuSelect" to="/thanh-vien">
          <span className="">Tin nhắn khách hàng</span>
        </Link>
        <Link className="menuSelect" to="/thanh-vien">
          <span className="">Thông Báo</span>
        </Link>
      </div> */}
    </div>
  );
}
