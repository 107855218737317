import React, { useState } from 'react';
import './style.scss';
import UploadMutipleImage from '@components/common/upload/UploadMutipleImage';
import { useAppDispatch, useAppSelector } from '@hook/index';
import { convert } from '@utils/convert';
import ImgVip from '@assets/images/vip1.png';
import ImgZalo from '@assets/zalo.png';
import ImgViber from '@assets/viber.png';
import ImgTele from '@assets/telegram.png';
import EditProfile from './Edit/EditProfile';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ModalView from '@components/layout/modal-view';
import { getAllLevel } from '@store/levelSlice';
export interface IProfileComponentProps {}

export default function ProfileComponent(props: IProfileComponentProps) {
  const [showEdit, setShowEdit] = React.useState<boolean>(true);
  const dispatch = useAppDispatch();
  const { data: DataLevel } = useAppSelector((state) => state.levelSlice);
  const { data } = useAppSelector((state) => state.authSlice);
  const handleChangeImg = (imgList: Object[]) => {};
  const handleChangeImgInfo = (imgList: Object[]) => {};
  const [text, setText] = useState('');
  const [isCopied, setIsCopied] = useState(false);
  React.useEffect(() => {
    dispatch(getAllLevel());
  }, []);
  const handleShow = () => {
    setShowEdit(!showEdit);
  };
  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (
    <div className="profile-container">
      <div className="rightModule">
        <div className="infoModule">
          <div className="inside-title icon icon-icon32px_Profile">
            <span style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <i style={{ fontSize: '30px' }} className="fa-regular fa-address-card"></i>Hồ sơ
            </span>
            <div>Nhằm đảm bảo an toàn tài khoản, Thông tin cá nhân không thể thay đổi sau khi xác nhận</div>
          </div>
          <div className="profileNew">
            <div className="profileInfo">
              <div className="vipLv">
                <div className="vipbg">
                  {/* <div className="circle" /> */}
                  <img src={DataLevel.vip.find((item) => item.level === data.level)?.image} alt="vip01" />
                </div>
                <div>{data.level_name}</div>
              </div>
              <div className="securityRate">
                <div className="title weak">Mạng xã hội</div>
                <div className="rateBar">
                  <a href={data.zalo} target="_blank" rel="noreferrer noopener">
                    <img src={ImgZalo} alt="Zalo" />
                  </a>
                  <a href={data.viber} target="_blank" rel="noreferrer noopener">
                    <img src={ImgViber} alt="Viber" />
                  </a>
                  <a href={data.telegram} target="_blank" rel="noreferrer noopener">
                    <img src={ImgTele} alt="Tete" />
                  </a>
                </div>
              </div>
            </div>
            {showEdit ? (
              <div className="profile">
                <button className="btn-edit" onClick={handleShow}>
                  <i className="fa-solid fa-pen-to-square"></i>
                </button>
                <div>
                  {' '}
                  <div>Ảnh đại diện</div>
                  <div>
                    {' '}
                    <img src={data.avatar || ''} alt="" />
                  </div>
                </div>
                <div className="wrap-img-list">
                  {' '}
                  <div>Giấy tờ khác</div>
                  <div className="img-list">
                    {' '}
                    {data.image_other?.map((item, index) => (
                      <ModalView key={index} url={item}>
                        <img src={item || ''} alt="" />
                      </ModalView>
                    ))}
                  </div>
                </div>
                <div>
                  <div>Họ và tên</div>
                  <div className="text">{data.real_name}</div>
                </div>
                <div>
                  <div>Ngày sinh</div>
                  <div className="text">{data.birthday}</div>
                </div>
                <div>
                  <div>Số ĐT</div>
                  <div className="text">{data.mobile}</div>
                </div>
                <div>
                  <div>Tên đăng nhập</div>
                  <div className="text">{data.username}</div>
                </div>
                <div>
                  <div>Email</div>
                  <div className="text">{data.email}</div>
                </div>
                <div>
                  <div>Rút điểm</div>
                  <div className="withdraw-label text">
                    {data.is_withdraw ? (
                      <i className="icon-check fa-solid fa-circle-check"></i>
                    ) : (
                      <i className="fa-solid fa-circle-xmark"></i>
                    )}
                  </div>
                </div>
                <div>
                  <div>Tỷ giá điểm</div>
                  <div className="text">1 = {convert.moneyInput(data.point_price)} đ (VNĐ)</div>
                </div>
                <div>
                  <div>Mã giới thiệu</div>
                  <div className="text">
                    <CopyToClipboard text={data.invite_code} onCopy={onCopyText}>
                      <div className="copy-area">
                        {data.invite_code}
                        {!isCopied ? (
                          <i className="fa-solid fa-copy"></i>
                        ) : (
                          <i className="fa-regular fa-circle-check"></i>
                        )}
                      </div>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
            ) : (
              <EditProfile handleShow={handleShow} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
