import * as React from 'react';
import './style.scss';
import { useAppSelector } from '@hook/index';
export interface IContactCoomponentProps {}

export default function ContactCoomponent(props: IContactCoomponentProps) {
  const { data } = useAppSelector((state) => state.settingSlice);
  return (
    <div className="content-contact layout-full">
      <div className="layout-max layout-container">
        <div className="container-fluid ">
          <div className="page-header">
            <div className="page-title">
              <span className="text">Liên hệ</span>
            </div>
            <div className="row contact-title">
              <p>Nếu có bất kỳ góp ý hay thắc mắc nào, vui lòng liên hệ:</p>
            </div>
          </div>

          <div className="row contact-icon-group">
            <div className="contact-item">
              <div className="text-center">
                <strong>Zalo</strong>
              </div>
              <div className="contact-icon">
                <img className="img-responsive" src={data.listSetting?.zalo_qrcode} alt="email icon" />
              </div>
              <div className="text-center">
                <strong>{data.listSetting?.zalo}</strong>
              </div>
            </div>
            <div className="contact-item">
              <div className="text-center">
                <strong>Telegram</strong>
              </div>
              <div className="contact-icon">
                <img className="img-responsive" src={data.listSetting?.telegram_qrcode} alt="email icon" />
              </div>
              <div className="text-center">
                <strong>{data.listSetting?.telegram}</strong>
              </div>
            </div>
            <div className="contact-item">
              <div className="text-center">
                <strong>Viber</strong>
              </div>
              <div className="contact-icon">
                <img className="img-responsive" src={data.listSetting?.viber_qrcode} alt="email icon" />
              </div>
              <div className="text-center">
                <strong>{data.listSetting?.viber}</strong>
              </div>
            </div>
          </div>
          <div className="date-time-info">
            <p className="text-center">
              Thời Gian Làm Việc:
              <br />
              Thứ Hai - Thứ Bảy | <strong>10:00h-19:00h</strong>
              <br />
              GMT +7
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
