import { IDataLisrRes, IDataRes, IItemListPage, IPageItem, IPageParam } from '@interfaces/Detail';
import httpRequest from './Api';

const ListPage = {
  getAll(): Promise<IDataLisrRes<IItemListPage>> {
    const url = '/list-pages';
    return httpRequest.get(url);
  },
};

export default ListPage;
