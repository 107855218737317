import PopoverArow from '@components/common/popover-arow';
import * as React from 'react';
import { Link } from 'react-router-dom';

export interface ITransactionProps {}

export default function Transaction(props: ITransactionProps) {
  const itemTransaction = () => (
    <div className="transaction-list">
      <Link className="menuSelect" to="/thanh-vien/rut-tien">
        <span className="">Rút điểm</span>
      </Link>
      <Link className="menuSelect" to="/thanh-vien/chuyen-diem">
        <span className="icon  ">Chuyển điểm</span>
      </Link>
      <Link className="menuSelect" to="/thanh-vien/lich-su-chuyen-diem">
        <span className="">Lịch sử chuyển điểm</span>
      </Link>
      <Link className="menuSelect" to="/thanh-vien/lich-su-rut-tien">
        <span className="">Lịch sử rút điểm</span>
      </Link>
      <Link className="menuSelect" to="/thanh-vien/lich-su-nap-tien">
        <span className="">Lịch sử nạp điểm</span>
      </Link>
    </div>
  );
  return (
    <div className="transaction">
      <PopoverArow content={itemTransaction} allowArow={true} classCustom="dark-style">
        <div className="transaction-content menu-item">
          <i className="fa-solid fa-building-columns"></i>
          <span>Giao dịch</span>
        </div>
      </PopoverArow>
    </div>
  );
}
