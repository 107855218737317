import React, { useEffect, useState } from 'react';
import Logo from '@assets/images/logo-main.png';

import { Badge, Popover, notification } from 'antd';
import PopoverArow from '@components/common/popover-arow';
import Language from '@components/layout/Language';
import Deposit from '@components/layout/Deposit';
import Transaction from '@components/layout/Transaction';
import Wallet from '@components/layout/Wallet';
import InfoUser from '@components/layout/InfoUser';
import ModalBasic from '@components/layout/modal';
import { useAppDispatch, useAppSelector } from '@hook/index';
import { getHeader } from '@store/homeSlice';
import ModalBasicMobile from '@components/layout/modal-mobile';
import { Link, useNavigate } from 'react-router-dom';

export interface IHeaderComponentProps {}

export default function HeaderComponent(props: IHeaderComponentProps) {
  const navigate = useNavigate();
  const { data } = useAppSelector((state) => state.authSlice);
  const { data: DataHome } = useAppSelector((state) => state.homeSlice);
  const { size } = useAppSelector((state) => state.commonSlice);
  return (
    <div className="layout-full header-main-layout">
      <div className="layout-max header-container">
        <div className="header-container__left">
          <Link to={'/'}>
            <div className="logo-img">
              {' '}
              <img src={DataHome.header.logo} alt="logo trang chủ" />
            </div>
          </Link>
        </div>
        <div className="header-container__right">
          <div className="function">
            {/* <div className="dowload-app menu-item">
              <i className="fa-solid fa-mobile-screen-button"></i>
              <span>Tải ứng dụng</span>
            </div> */}
            {/* <Language /> */}
            {data.isLogin ? (
              <>
                <Deposit />
                <Transaction />
                {/* <Wallet /> */}
                <InfoUser />
                {/* <Badge dot={true}>
                  <i className="email-icon fa-regular fa-envelope"></i>
                </Badge> */}
              </>
            ) : (
              <>
                <button className="sign-in" onClick={() => navigate('/dang-ky')}>
                  Đăng ký
                </button>
                {size > 768 ? (
                  <ModalBasic>
                    <button className="login">Đăng nhập</button>
                  </ModalBasic>
                ) : (
                  <ModalBasicMobile>
                    {' '}
                    <button className="login">Đăng nhập</button>
                  </ModalBasicMobile>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
